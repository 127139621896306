import React, {Component} from 'react';
import { Desktop, Tablet, Mobile, Phone } from './shared';
import cards from '../assets/images/lambo-background.jpg'
import cardsMOBILE from '../assets/images/lambo-backgroundMOBILE.jpg'

import nft1 from '../assets/images/background2.jpg'
import nft2 from '../assets/images/background3.jpg'
import nft1TABLET from '../assets/images/background2TABLET.jpg'
import nft2TABLET from '../assets/images/background3TABLET.jpg'
import nft1MOBILE from '../assets/images/nft1MOBILE.jpg'
import nft2MOBILE from '../assets/images/nft2MOBILE.jpg'
import nft3MOBILE from '../assets/images/nft3MOBILE.jpg'
import nft4MOBILE from '../assets/images/nft4MOBILE.jpg'

import video from "..//assets/images/cardloop.mp4";

// import { Desktop, Tablet, Mobile, Phone } from './shared';


// import Footer from '../navbar/Footer';

const title1 = {
    color:'white',
    paddingTop:'20%',
    paddingLeft:'10%'
}
const title1Mobile = {
    color:'white',
    paddingTop:'35%',
    paddingLeft:'10%',
    fontSize:'35px'
}
const caption = {
    float:'left',
    color:'white',
    paddingLeft:'10%',
    fontSize:'28px',
    paddingTop:'0'
}
const passage = {
    float:'left',
    fontSize:'14px',
    paddingTop:'0',
    lineHeight:'2.2',
    textAlign:'left',
}
const passageMOBILE = {
    float:'left',
    fontSize:'14px',
    paddingTop:'0',
    lineHeight:'2.2',
    textAlign:'center',
}

const title2 = {
    textAlign:'left',
    fontSize:'34px',
    paddingTop:'15%',

}
const title2MOBILE = {
    textAlign:'center',
    fontSize:'28px'

}




class AutomotiveTradingCardsPack1 extends Component {
    render(){
        
       
    
        return(
            
            <div >
                <Desktop>
                    <div style={{ backgroundImage: `url(${cards})`,width:'100%',height:'1023px' }}>
                        <div className = 'col s6 m6 l6'>
                            <h1 style = {title1}>Automotive classic <br/>Trading Card NFTs</h1>    
                            <p style = {caption}>Pack #1</p>    
                        </div>
                        <div className = 'col s6 m6 l6'>

                        </div>
                    </div>
                    <div className = "row" style = {{padding:'0',maxWidth:'100%',marginBottom:'0'}}>
                        <div className = "col s5 m5 l5" style = {{padding:'5%'}}>
                            <h1 style = {title2}>Lamborghini Countach LP5000 S</h1>
                            <p style = {passage}>
                            The Lamborghini Countach was a supercar produced by Lamborghini in Italy. 
                            The first prototype emergedin 1971, and production lasted until 1990. 
                            It did not pioneer but did popularise the wedge-shaped,sharply angled look popular in many high performance cars since.
                                
                            
                            </p>
                            <p style ={passage}>
                                There will be a total of 5 NFT trading cards featuring the legendary Lamborghini Countach. The pack will be up for auction on a marketplace that is TBD. Each card will feature the issue number and the car specs on the back . The front of the card features 
                                a top view of Countach's styling and visual impression which caused it to become an icon of great design.
                            </p>
                        </div>
                        <div className = "col s7 m7 l7" style = {{padding:'0',marginBottom:'0', backgroundColor:'#0d0e11'}}>
                        <video style = {{width:'100%',height:'auto'}} muted loop autostart autoPlay src={video} type="video/mp4" /> 
                        </div>
                    </div>
                    <div className = "row" style={{ backgroundImage: `url(${nft1})`,width:'100%',height:'1023px',marginBottom:'0' }}>
                    
                    </div>
                    <div className = "row" style={{ backgroundImage: `url(${nft2})`,width:'100%',height:'1023px',marginBottom:'0' }}>
                    
                    </div>
                    <div className = "row">
                        <h2 style = {{textAlign:'center',padding:'10%'}}>Coming soon</h2>
                    </div>
                </Desktop>
                <Tablet>
                    <div style={{ backgroundImage: `url(${cards})`,width:'100%',height:'1023px' }}>
                        <div className = 'col s6 m6 l6'>
                            <h1 style = {title1}>Automotive classic <br/>Trading Card NFTs</h1>    
                            <p style = {caption}>Pack #1</p>    
                        </div>
                        <div className = 'col s6 m6 l6'>

                        </div>
                    </div>
                    <div className = "row" style = {{padding:'0',maxWidth:'100%',marginBottom:'0'}}>
                        <div className = "col s5 m5 l5" style = {{padding:'5%'}}>
                            <h1 style = {title2}>Lamborghini Countach LP5000 S</h1>
                            <p style = {passage}>
                            The Lamborghini Countach was a supercar produced by Lamborghini in Italy. 
                            The first prototype emergedin 1971, and production lasted until 1990. 
                            It did not pioneer but did popularise the wedge-shaped,sharply angled look popular in many high performance cars since.
                                
                            
                            </p>
                            <p style ={passage}>
                                There will be a total of 5 NFT trading cards featuring the legendary Lamborghini Countach. The pack will be up for auction on a marketplace that is TBD. Each card will feature the issue number and the car specs on the back . The front of the card features 
                                a top view of Countach's styling and visual impression which caused it to become an icon of great design.
                            </p>
                        </div>
                        <div className = "col s7 m7 l7" style = {{padding:'0',marginBottom:'0', backgroundColor:'#0d0e11',height:'1000px'}}>
                        <video style = {{width:'100%',height:'auto',paddingTop:'15%'}} muted loop autostart autoPlay src={video} type="video/mp4" /> 
                        </div>
                    </div>
                    <div className = "row" style={{ backgroundImage: `url(${nft1TABLET})`,width:'100%',height:'693px',marginBottom:'0' }}>
                    
                    </div>
                    <div className = "row" style={{ backgroundImage: `url(${nft2TABLET})`,width:'100%',height:'693px',marginBottom:'0' }}>
                    
                    </div>
                    <div className = "row">
                        <h2 style = {{textAlign:'center',padding:'10%'}}>Coming soon</h2>
                    </div>
                </Tablet>
                <Mobile>
                    <div style={{ backgroundImage: `url(${cardsMOBILE})`,width:'100%',height:'623px' }}>
                        <div className = 'col s6 m6 l6'>
                            <h1 style = {title1Mobile}>Automotive classic <br/>Trading Card NFTs</h1>    
                            <p style = {caption}>Pack #1</p>    
                        </div>
                        <div className = 'col s6 m6 l6'>

                        </div>
                    </div>
                    <div className = "row" style = {{padding:'0',maxWidth:'100%',marginBottom:'0'}}>
                    <div className = "col s12 m12 l12" style = {{padding:'0',marginBottom:'0', backgroundColor:'#0d0e11',height:'auto'}}>
                        <video style = {{width:'100%',height:'auto',paddingTop:'15%'}} muted loop autostart autoPlay src={video} type="video/mp4" /> 
                        </div>
                        <div className = "col s12 m12 l12" style = {{padding:'5%'}}>
                            <h1 style = {title2MOBILE}>Lamborghini Countach LP5000 S</h1>
                            <p style = {passageMOBILE}>
                            The Lamborghini Countach was a supercar produced by Lamborghini in Italy. 
                            The first prototype emergedin 1971, and production lasted until 1990. 
                            It did not pioneer but did popularise the wedge-shaped,sharply angled look popular in many high performance cars since.
                                
                            
                            </p>
                            <p style ={passageMOBILE}>
                                There will be a total of 5 NFT trading cards featuring the legendary Lamborghini Countach. The pack will be up for auction on a marketplace that is TBD. Each card will feature the issue number and the car specs on the back . The front of the card features 
                                a top view of Countach's styling and visual impression which caused it to become an icon of great design.
                            </p>
                        </div>
                       
                    </div>
                    <div className = "row" style={{ width:'100%',height:'auto',marginBottom:'0',backgroundColor:'#1b1e1f' }}>
                        <img src = {nft1MOBILE}/>
                        <img src = {nft2MOBILE}/>
                        <img src = {nft3MOBILE}/>
                        <img src = {nft4MOBILE}/>
                       
                    </div>
                    <div className = "row">
                        <h2 style = {{textAlign:'center',padding:'10%'}}>Coming soon</h2>
                    </div>
                   
                </Mobile>
            </div>
        )
    }
}


export default AutomotiveTradingCardsPack1