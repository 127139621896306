import React from 'react';
import ReactDOM from 'react-dom';
import DropzoneComponent from 'react-dropzone-component';

export default class DefaultUpload extends React.Component {
    constructor(props) {
        super(props);
        

        this.djsConfig = {
            addRemoveLinks: true,
            acceptedFiles: "image/jpeg,image/png,image/gif"
        };

        this.componentConfig = {
            iconFiletypes: ['.jpg', '.png', '.gif'],
            showFiletypeIcon: true,
            postUrl: 'https://designerspendroplet.getdpsvapi.com/'
        };

        // If you want to attach multiple callbacks, simply
        // create an array filled with all your callbacks you idiot.
        this.callbackArray = [() => console.log('Hi!'), () => console.log('Ho!')];

        // Simple callbacks work too, of course
        this.callback = () => console.log('Hello!');

        this.success = file => console.log('uploaded', file);

        this.progress = file => console.log('progress', file);

        this.removedfile = file => console.log('removing...', file);

        this.dropzone = null;
        console.log(props)
        console.log(this.props)
    }

    render() {
        const config = this.componentConfig;
        const djsConfig = this.djsConfig;

        // For a list of all possible events (there are many), see README.md!
        const eventHandlers = {
            init: dz => this.dropzone = dz,
            drop: this.callbackArray,
            addedfile: this.callback,
            success: this.success,
            removedfile: this.removedfile,
            uploadprogress: this.progress
        }

        return (
            <DropzoneComponent  eventHandlers={this.props.onChange} config={config} eventHandlers={eventHandlers} djsConfig={djsConfig} />
        )
    }
}