import React, {Component} from 'react';
import {Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import Footer from '../navbar/Footer'
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Popup from "reactjs-popup";
import { Button } from 'react-bootstrap';
import Modal from 'react-modal';

import { Desktop, Tablet, Mobile, Phone } from '../shared';

import '../../assets/stylesheets/Generate.css'
import Video from "./assets/LandingVideo.mp4";
import logo from "./assets/logo.png";



 

class Generate extends Component {

    
    
    constructor(props){
        
        super(props);
        this.state= {
            items :[],
            users:[],
            collectedimage:'',
            uuid: this.props.auth.uid,
            displayName: props.auth.displayName,
            userPhotoUrl: props.auth.photoURL,
            userphotos:[],
            id:[],
            isLoaded: false,
            selectedImage:'',
            limit:50,
            GeneratePreview:'',
            index : 0,
            isLoggedIn:false,
            setIsOpen:true,
            modalIsOpen:true,
            isGenerating:false,
            imageDownload:''
            
           

            // product: {
            //     username:'',
            //     email:'',
            //     password:''
            // },
            
        }
    
    }

    createNotification = (type) => {
        return () => {
           
          switch (type) {
            case 'info':
              NotificationManager.info('Info message');
              break;
            case 'success':
              NotificationManager.success('Success ', 'Image was added to your collection');
              const {uuid,collectedimage} = this.state;
              fetch(`https://designerspendroplet.getdpsvapi.com/collectgenerate?uuid=${uuid}&post_id=${collectedimage}`)
              .catch (err => console.err(err))
              break;
            case 'warning':
              NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
              break;
              case 'error':
                NotificationManager.error('error ', 'Image was removed from your collection');
                

                fetch(`https://designerspendroplet.getdpsvapi.com/removecollectpost?uuid=${uuid}&post_id=${collectedimage}`)
                .then(console.log("this worked stuff REMOVED"))
                .catch (err => console.err(err))
                break;
          }
        };
      };

    onChange = (e) => {
        // event to update state when form inputs change
        switch (e.target.name) {
            case 'selectedFile':
              this.setState({ selectedFile: e.target.files[0] });
              break;
            default:
              this.setState({ [e.target.name]: e.target.value });
          }
    }

    componentDidMount() {
        document.addEventListener('scroll', this.trackScrolling);
        const {uuid} = this.state
         
            fetch('https://designerspendroplet.getdpsvapi.com/Generate')
    
         .then((res1) => (res1.json()))
         .then((data1) => this.setState({
             isLoaded:true,
             userphotos:data1
            
         }))      
    }
    
    onCollect = _ => {  
    const {uuid,collectedimage} = this.state;
    fetch(`https://designerspendroplet.getdpsvapi.com/collectgenerate?uuid=${uuid}&post_id=${collectedimage}`)
    .then(console.log("this worked stuff submitted"))
    .catch (err => console.err(err))
    }

    onRemoveCollect = _ => {  
        const {uuid,collectedimage} = this.state;
        fetch(`https://designerspendroplet.getdpsvapi.com/removecollectpost?uuid=${uuid}&post_id=${collectedimage}`)
        .then(console.log("this worked stuff REMOVED"))
        .catch (err => console.err(err))
    }
    openModal() {
    this.setState({ open: true });
    }
    closeModal() {
        this.setState({ open: false });
    }


    
    handleClick(e) { if (e) {e.preventDefault()}; }

     toggleImage = () => {
        this.setState({ isGenerating :true });
        this.setState({ index : this.state.index + 1 });
        setTimeout(() => {
            this.setState({ isGenerating :false });
          }, 800);
       
      }
     logDownload = (e) => {
       const {imagedownload} = this.state.userphotos.data[this.state.index].imageUrl;
       this.setState({ imageDownload : this.state.userphotos.data[this.state.index].imageUrl});
       console.log(this.state.userphotos.data[this.state.index].imageUrl)
       
      }

     closeModal = () => {
        this.setState({ modalIsOpen :false });
      }

    render(){
        var { isLoaded,items} = this.state;
        const {items} = this.state
        const {auth,authError} = this.props;

        const {modalIsOpen,setIsOpen} = this.state;
        function openModal() {
            setIsOpen(true);
        }
        
        function afterOpenModal() {
            // references are now sync'd and can be accessed.
        }
        
        function closeModal(){
            setIsOpen(false);
        }
        // const random = this.state.userphotos.data[Math.floor(Math.random() * this.state.userphotos.data.length)];
    
        if (!isLoaded)    {return <div style = {{paddingTop:'50%'}}><h1 >Loading...</h1></div>
        }
        if(!auth.uid) return <Redirect to='/signup'/>
        
        else{
            const customStyles = {
                content : {
                  top                   : '50%',
                  left                  : '50%',
                  right                 : 'auto',
                  bottom                : 'auto',
                  marginRight           : '-50%',
                  transform             : 'translate(-50%, -50%)'
                }
              };
      

        return(   
            
            <div style = {{paddingBottom:"5%",paddingTop:"5%",}}>
                <Desktop>
                    <div style = {{backgroundColor:'#fcfbfd',paddingBottom:"30%"}}>
                        <video style = {{width:'100%',height:'720px',position:'relative'}} muted loop autostart autoPlay src={Video} type="video/mp4" />    
                        <img style = {{
                            margin:'auto',
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width: '20%'}} src = {logo}
                        />
                        <h1 style = {{
                           textAlign:'center',
                           fontSize:'18px'}}>Under construction
                        </h1>
                        <a href = "https://designerspen.com/AboutVizcom"><button className = ' tryitbtn lighten-1 z-depth-0' >Learn More </button></a>
                        
                    </div>
                </Desktop> 
                <Tablet>
                <div style = {{backgroundColor:'#fcfbfd',paddingBottom:"30%"}}>
                    <video style = {{width:'100%',height:'720px',position:'relative'}} muted loop autostart autoPlay src={Video} type="video/mp4" />    
                    <img style = {{
                        margin:'auto',
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '20%'}} src = {logo}
                    />
                    <h1 style = {{
                        textAlign:'center',
                        fontSize:'18px'}}>Under construction
                    </h1>
                    <a href = "https://designerspen.com/AboutVizcom"><button className = ' tryitbtn lighten-1 z-depth-0' >Learn More </button></a>
                        
                </div>           
                </Tablet>    
                <Mobile>
                <div style = {{backgroundColor:'#fcfbfd',paddingBottom:"100%"}}>
                    <video style = {{width:'100%',height:'320px',position:'relative',paddingTop:'20%'}} muted loop autostart autoPlay src={Video} type="video/mp4" />    
                    <img style = {{
                        margin:'auto',
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '40%'}} src = {logo}
                    />
                    <h1 style = {{
                        textAlign:'center',
                        fontSize:'12px',
                        paddingBottom:'30%'}}>Under construction
                    </h1>
                    <a href = "https://designerspen.com/AboutVizcom"><button className = ' mobiletryitbtn lighten-1 z-depth-0' >Learn More </button></a>
                        
                </div> 
                    
                </Mobile>
            </div>
        )
        }
    }
}


const mapStateToProps = (state) => { // 1.) Gives acces to the authentication state 
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile

    }
}

export default   connect(mapStateToProps) (Generate);