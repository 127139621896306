import React from 'react'
import {connect} from 'react-redux'
import {firestoreConnect} from 'react-redux-firebase'
import {compose} from 'redux'
import {Redirect} from 'react-router-dom'
import moment from 'moment'
const ProjectDetails = (props) => {
    
    const {project,auth} = (props);
    if(!auth.uid) return <Redirect to='/signin'/>
    if (project) {
        return (

            //somewhere in here project.selectedFile
        <div className = 'container section project-details'>
            <div className ='card z-depth-0'>
                <div className = "card-content">
                    <span className ='card-title'> {project.title} </span>
                    <p>{project.content}</p>
                    <img src = {project.url}/>
                </div>
                <div className = 'card-action grey lighten-4 grey-text'>
                    <div>{project.authorFirstName} {project.authorLastName}</div>
                    <div> {moment(project.createdAt.toDate()).calendar()} </div>
                </div>
            </div>
        </div>
    )
    } else {
        return (
            <div className = 'container center'><p>Loading....</p> </div> //later insert loading animation 
            )
        }   
    }

    


const mapStateToProps = (state,ownProps) => {
    console.log(state)
    console.log(ownProps.match.params.id)
    const id = ownProps.match.params.id;
    const projects = state.firestore.data.projects;
    const project = projects ? projects[id] : null //projects is just an object with the diffrent properties 
    return {
        project:project,
        auth: state.firebase.auth
        
    }
}
export default compose(

    connect(mapStateToProps),
    firestoreConnect ([
        {collection:'projects'}
         ])
)(ProjectDetails)